@require "../styles/globals"

.lbp-header
  striped-background-pattern()
  align-items: end
  color: navy
  grid-column: 1
  grid-row: 1
  min-height: 10rem
  overflow: hidden
  position: relative
  text-align: center

  @media screen and (min-width: narrow)
    grid-column: 1/6
    grid-row: 1

  svg
    fill: sky
    left: 50%
    position: absolute
    stroke: navy
    stroke-width: 3px
    top: 1rem
    width: 38rem
    margin-left: -(@width / 2)

    @media screen and (min-width: narrow)
      left: 50%
      margin-left: -22rem
      top: 1rem
      width: 44rem

  h1
    //font-family: "Pragati Narrow"
    font-family: "Anton"
    font-size: 2.5rem
    margin: 0
    position: relative
    top: 2.25rem
    white-space: nowrap
    z-index: 1

    @media screen and (min-width: narrow)
      font-size: 3.5rem
      top: 2.25rem
  
.lbp-subheader
  background-color: navy
  color: sky
  grid-column: 1
  grid-row: 2
  text-align: center

  @media screen and (min-width: narrow)
    grid-column: 1/6
    grid-row: 2

  h2
    font-family: "Pragati Narrow"
    font-size: 1rem
    font-spacing: wide
    font-stretch: expanded
    letter-spacing: 0.2em
    margin: 0
    padding: 0.5rem 0
    text-transform: lowercase

    @media screen and (min-width: narrow)
      font-size: 1.25rem