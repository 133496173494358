@require "../styles/globals"

.lbp-footer
  background: linear-gradient(darken(navy, 50%), navy 10%)
  color: white
  font-size: 0.75rem
  grid-column: 1
  padding: gutter-width page-margin
  position: relative
  text-align: right

  @media screen and (min-width: narrow)
    grid-column: 1/6

  @media screen and (min-width: wide)
    padding: gutter-width 2*page-margin

  @media screen and (min-width: showoff)
    padding: gutter-width 3*page-margin
  
  a
    color: mustard

  span
    display: inline-block