.lbp-signed-in-user
  align-items: center

  img, span, i
    margin: 0 0.25em

  .lbp-profile-image
    border-radius: 1em
    height: 2em
    width: 2em
  
  .lbp-sign-out
    cursor: pointer