@require "../styles/globals"

.lbp-promotion
  background-color: red
  border-color: mustard
  color: lighten(mustard, 50%)
  display: flex
  flex-flow: column
  grid-column: 1
  justify-content: center
  min-height: 10rem
  padding: gutter-height page-margin

  h3
    color: white
    margin-bottom: 1rem
    text-shadow 0 0 4px mustard

  p
    color: white
    margin-top: 0

  @media screen and (min-width: narrow)
    grid-column: 1/6
    padding: gutter-height page-margin + gutter-width

  @media screen and (min-width: wide)
    padding: gutter-width 2*page-margin + gutter-width

  @media screen and (min-width: showoff)
    padding: gutter-width 3*page-margin + gutter-width