@require "../styles/globals"

.lbp-features
  background-color: sky
  border-bottom: 0.25 * gutter-width solid navy
  border-top: 0.25 * gutter-width solid navy
  display: grid
  grid-column: 1
  grid-gap: gutter-width
  grid-template-columns: 1fr
  padding: page-margin 0

  @media screen and (min-width: tiny)
    padding: page-margin

  @media screen and (min-width: narrow)
    grid-column: 1/6
    grid-template-columns: 1fr 1fr
    grid-template-rows: minmax(min-content, 10rem)
    padding: page-margin page-margin + gutter-width

  @media screen and (min-width: wide)
    grid-column: 1/6
    grid-template-columns: 1fr 1fr 1fr
    grid-template-rows: minmax(min-content, 10rem)
    padding: page-margin 2 * page-margin + gutter-width

  @media screen and (min-width: showoff)
    padding: gutter-width 3 * page-margin + gutter-width 
