@require "../styles/globals"

.lbp-editor-app
  background-color: white
  color: navy
  display: flex
  flex-flow: column
  min-height: 100vh

  .lbp-editor-header
    background-color: mustard
    border: 5px solid navy
    border-left-width: 0
    border-right-width: 0
    color: navy
    display: flex
    flex: 0 0 auto
    flex-flow: row
    margin: 0
    padding: 1em
    text-shadow: 0 0 4px royal

    h3
      display: flex
      flex: 1 0 auto
      margin: 0
    .lbp-authenticator
      display: flex
    .lbp-signed-in-user
      display: flex
      flex: 0 0 auto

  .lbp-toolbar
    background-color: navy
    display: flex
    flex: 0 0 auto
    flex-flow: row
    font-family: "Pragati Narrow"
    font-size: 16px
    padding: 1em 1em 0 1em

    .lbp-toolbar-link
      background-color: royal
      color: sky
      display: block
      font-size: 1.5em
      margin: 0 0.25em
      padding: 0.1em 1em 0 1em
      text-decoration: none
      z-index: 1

      &--current
        background-color: sky
        color: navy

  &-content
    flex: 1 0 auto
    border-bottom: 5px solid navy
    padding: 2em

    table
      border: 1px solid royal
      font-family: "Pragati Narrow"
      font-size: 16px
      width: calc(100% - 2em)
      
      td
        padding: 0 0.5em
      
      .lbp-article-actions
        display: flex
        flex-flow: row
        font-size: 12px

        .lbp-button
          margin: 0 0.5em

  .lbp-footer
    flex: 0 0 auto
  
.lbp-articles
  
  thead
    font-size: 1.5em