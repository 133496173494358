@require "../styles/globals"

.lbp-article-display
  background-color: white
  color: navy
  font-family: "Noto Serif", serif
  font-size: 20px
  grid-column: 1
  grid-template-columns: 1fr 1fr
  grid-template-rows: minmax(min-content, 10rem)
  padding: page-margin 15%
  margin: auto
  max-width: 48rem

  @media screen and (min-width: narrow)
    grid-column: 1/6

  &-footer
    background-image: url("../assets/book-parade.png")
    background-position bottom center
    background-repeat: repeat no-repeat
    background-size: contain
    grid-column: 1;
    height: 150px;

    @media screen and (min-width: narrow)
      grid-column: 1/6

  a
    color: turquoise
    text-decoration: underline
  
  figure
    margin: 0

  .lbp-article-paragraph
    margin-bottom: 1em
  
  .lbp-article-image
    width: 100%;
