@require "../styles/globals"

.lbp-article-form-toolbar
  align-items: center
  background-color: white
  border-bottom: 1px solid navy
  display: flex
  flex-flow: row
  flex-wrap: wrap
  font-family: "Pragati Narrow"
  font-size: 16px
  margin-bottom: 1em
  padding-bottom: 1em
  position: sticky
  top: 0
  z-index: 2

  button
    margin: 0 0.125em

  section
    margin: 0 1em
  
  &-title
    flex: 1 0 100%

  &-buttons
    flex: 1 0 auto

.lbp-article-editor
  .public-DraftEditor-content
    min-height: 50vh
  