@require "../styles/globals"

shadow-depth = 3px

.lbp-feature-card
  background-color: white
  border: 0.25 * gutter-width solid turquoise
  border-left-style: none
  border-right-style: none
  box-shadow: inset 1px 1px shadow-depth darken(@background-color, 50%)
  cursor: pointer
  margin: 0
  padding: gutter-width

  h3, h3 a
    color: royal
    font-family: "Pragati Narrow"
    margin-bottom: 1em
    text-shadow 0 0 4px turquoise

  img
    margin-bottom: 1em
    width: 100%
  
  @media screen and (min-width: tiny)
    border-style: solid

  @media screen and (min-width: narrow)
    border-radius: 0.1 * gutter-width
    margin: 0
    padding: gutter-width

  &:nth-child(4n+1)
    background-color: royal
    border-color: red
    box-shadow: inset 1px 1px shadow-depth darken(@background-color, 50%)
    color: mustardText

    h3, h3 a
      color: white
      text-shadow 0 0 4px mustard

  &:nth-child(5n+2)
    background-color: turquoise
    border-color: royal
    box-shadow: inset 1px 1px shadow-depth darken(@background-color, 50%)
    color: white

    h3, h3 a
      color: mustardText
      text-shadow 0 0 4px red

  &--interview, &--interview:nth-child(3n+1), &--interview:nth-child(4n)
    background: fixed mustard url("../assets/ben-white-148435-unsplash.jpg") center/cover no-repeat
    background-blend-mode: multiply
    border-color: mustard
    color: white
    min-height: 12rem
    text-shadow: 0.1rem 0.1rem 0.1rem navy

    @media screen and (min-width: narrow)
      grid-column: 1/3
      grid-row: 2

    h3, h3 a
      color: white
 