@require "../styles/globals"

.lbp-app
  display: grid
  font-family: "Noto Serif", serif
  font-size: 16px
  grid-gap: 0 gutter-width
  grid-template-rows: min-content min-content 1fr min-content min-content
  min-height: 100vh

  @media screen and (min-width: narrow)
    grid-template-columns: page-margin 1fr 1fr 1fr page-margin

  @media screen and (min-width: wide)
    grid-template-columns: 2*page-margin 1fr 1fr 1fr 2*page-margin

  @media screen and (min-width: showoff)
    grid-template-columns: 3*page-margin 1fr 1fr 1fr 3*page-margin
  