@require "../styles/globals"

.lbp-article-title
  background: none
  border: none
  color: navy
  display: block
  font-family: "Bree Serif", serif
  font-size: 2em
  font-weight: bold
  line-height: 1.1
  margin-bottom: 1.5em
  margin-top: 0
  padding: 0
  width: 100%