@import "src/styles/globals"

body
  background: white
  color: charcoal
  display: block
  margin: 0
  overflow: visible;
  padding: 0
  position: relative
  min-width: minimum

h1, h2, h3, h4
  font-family: "Bree Serif", serif
  font-weight: bold
  line-height: 1.1
  margin-bottom: 1.5em
  margin-top: 0

h3
  font-size: 2rem

a
  color: navy
  text-decoration: none
