@require "../styles/globals"

.lbp-accent-stripe
  grid-column: 1
  height: gutter-width
  position: relative
  striped-background-pattern()
  z-index: 1

  @media screen and (min-width: narrow)
    grid-column: 1/6